import currency from 'currency.js';
import produce from 'immer';
import { v4 as uuid } from 'uuid';
import _ from 'lodash';

import { defineState } from 'redux-localstore';

import { STATUS_PDV, TIPO_DOCUMENTO } from '~/constants';
import {
  LIMPAR_VENDA,
  SET_STATUS,
  INICIAR_VENDA_SUCCESS,
  SET_SHOWMODAL_ATALHOS_PDV,
  SET_SHOWMODAL_CONSULTA_CLIENTE,
  SET_SHOWMODAL_CONSULTA_PRODUTO,
  SET_SHOWMODAL_CONSULTA_PRODUTO_REGRA,
  SET_SHOWMODAL_IDENTIFICAR_CLIENTE,
  SET_SHOWMODAL_CONSULTA_VENDEDOR,
  SET_SHOWMODAL_CONSULTA_PROFISSIONAL,
  SET_SHOWMODAL_CONSULTA_OPERACAO,
  SET_SHOWMODAL_CONSULTA_PAGAMENTO,
  SET_SHOWMODAL_CONDICAO_PAGAMENTO_PRODUTO,
  SET_SHOWMODAL_SALDO_ESTOQUE_PRODUTO,
  SET_SHOWMODAL_EXPEDICAO_MERCADORIA,
  SET_SHOWMODAL_INFORMACAO_COMPLEMENTAR,
  SET_SHOWMODAL_ENDERECO_CLIENTE,
  SET_SHOWMODAL_LOADING_MENSAGEM,
  SET_SHOWMODAL_LOADING_SALVAMENTO,
  SET_SHOWMODAL_CONFIRMA_PAGAMENTO,
  SET_SHOWMODAL_LIBERACAO_PEDIDO,
  SET_SHOWMODAL_MENSAGEM_TEF,
  SET_SHOWMODAL_MENU_TEF,
  SET_SHOWMODAL_PAGAMENTO_POR_SALDO,
  SET_SHOWMODAL_DESCONTO,
  SET_SHOWMODAL_ACRESCIMO,
  UPDATE_LOADING_MENSAGEM,
  SET_LOADING,
  SET_LOADING_PRODUTO,
  SET_LOADING_PAGAMENTO,
  SET_LOADING_SALVAMENTO,
  SET_LOADING_IMPRESSAO,
  SET_LOADING_TRANSMISSAO,
  SELECIONAR_VENDEDOR,
  ADICIONAR_PRODUTO_SUCCESS,
  REMOVER_PRODUTO_SUCCESS,
  ATUALIZAR_QUANTIDADE_SUCCESS,
  ATUALIZAR_DESCONTO_ITEM,
  ATUALIZAR_DESCONTO_ITEM_PERCENTUAL,
  ATUALIZAR_ACRESCIMO_ITEM,
  ATUALIZAR_ITEM_PAGAMENTO,
  ADICIONAR_PAGAMENTO_SUCCESS,
  REMOVER_PAGAMENTOS_SUCCESS,
  SELECIONAR_MEIO_PAGAMENTO_SUCCESS,
  SELECIONAR_MEIO_PAGAMENTO_ENTRADA,
  SELECIONAR_CLIENTE_SUCCESS,
  SELECIONAR_PRODUTO_SUCCESS,
  SELECIONAR_CONDICAO_PAGAMENTO_SUCCESS,
  SELECIONAR_OPERACAO_SUCCESS,
  IDENTIFICAR_CLIENTE_SUCCESS,
  SELECIONAR_ENDERECO_CLIENTE,
  REMOVE_CLIENTE,
  FECHAR_VENDA_SUCCESS,
  CANCELAR_PRODUTO_SUCCESS,
  LIMPAR_PAGAMENTOS,
  ATUALIZAR_ITENS,
  ATUALIZAR_ACRESCIMO_SUCCESS,
  ATUALIZAR_ACRESCIMO_PERCENTUAL_SUCCESS,
  ATUALIZAR_DESCONTO_SUCCESS,
  ATUALIZAR_DESCONTO_PERCENTUAL_SUCCESS,
  ATUALIZAR_DESCONTO_SUCCESS_SEM_PARAMS,
  ATUALIZAR_DESCONTO_PERCENTUAL_SUCCESS_SEM_PARAMS,
  ALTERAR_TIPO_DOCUMENTO_SUCCESS,
  ALTERAR_UNIDADE_COMERCIAL,
  ALTERAR_ARMAZEM,
  ALTERAR_EXPEDICAO_MERCADORIAS,
  EDITAR_ITEM_VENDA,
  ATUALIZAR_PRODUTO,
  FECHAR_VENDA_ERROR,
  SET_FOCUS_CONSULTA_PRODUTO,
  SET_FOCUS_QUANTIDADE,
  SELECIONAR_ENDERECO_ENTREGA,
  SET_INFORMACAO_COMPLEMENTAR,
  SET_TRANSFERENCIA_ITEM,
  ATUALIZAR_NUMERO_DOCUMENTO_CARTAO,
  ATUALIZAR_BANDEIRA_CARTAO,
  ADICIONAR_PARCELAS_SUCCESS,
  SET_MULTIPLOS_PAGAMENTOS,
  SET_SHOWMODAL_LISTA_PAGAMENTOS,
  SET_SHOWMODAL_IMPORTAR_DOCUMENTO,
  SET_SHOWMODAL_CADASTRO_CHEQUE,
  ADICIONAR_CHEQUE_PARCELA,
  REMOVER_CHEQUE_PARCELA,
  SET_SHOWMODAL_PREVIEW_DANFE,
  SET_SHOWMODAL_CONSULTA_RAPIDA_PRODUTO,
  ATUALIZAR_PARCELAS,
  REMOVER_PROFISSIONAL,
  REMOVER_PARCELA,
  SET_SHOWMODAL_TROCA_USUARIO,
  SET_SHOWMODAL_FRETE,
  ATUALIZAR_FRETE_SUCCESS
} from './pdv-constants';
import { atualizaTotais, atualizaValoresItens, calculaValorTotalItem } from './pdv-helper';
import { verificaItemEstoque } from './pdv-service-utils';
import toast from '~/services/toast';
import { getItensDescontoNaoPermitido } from './pdv-selectors';

export const INITIAL_STATE = {
  status: STATUS_PDV.IDENTIFICACAO,
  tipoDocumento: TIPO_DOCUMENTO.PEDIDO,
  mensagemLoading: '',
  documentoSalvo: null,
  itemVenda: null,
  itemPagamento: null,
  matrizCondicaoPagamento: [],
  pagamentoConfirmado: false,
  clienteIdentificado: false,
  verificaMovimentaFinanceiro: true,
  multiplosPagamentos: false,
  loadingSalvamento: false,
  showModalLoadingSalvamento: {
    show: false
  },
  showModalConfirmaPagamento: {
    show: false
  },
  showModalCadastroCheque: {
    show: false
  },
  venda: {
    intCliente: null,
    fisOperacao: null,
    fatVendedor: null,
    fatTabelavenda: null,
    fatCondicaopagamento: null,
    finMeiopagamento: null,
    itens: [],
    parcelas: [],
    valorMercadoria: 0,
    valorAcrescimo: 0,
    valorDesconto: 0,
    valorFrete: 0,
    valorSeguro: 0,
    valorDespesa: 0,
    valorIsento: 0,
    valorNaoIncidencia: 0,
    valorRecebido: 0,
    valorTributoIbpt: 0,
    valorTotal: 0,
    valorTotalFinanceiro: 0, //Virtual
    valorSaldoPagar: 0, //Virtual
    impostoIcmsBase: 0,
    impostoIcms: 0,
    impostoIcmsStBase: 0,
    impostoIcmsSt: 0,
    impostoIpiBase: 0,
    impostoIpi: 0,
    impostoPisBase: 0,
    impostoPis: 0,
    impostoCofinsBase: 0,
    impostoCofins: 0,
    impostoFcpUfDest: 0,
    impostoFcpStRet: 0,
    impostoIcmsDesonerado: 0,
    impostoIcmsUfDest: 0,
    impostoIcmsUfRemet: 0,
    impostoIpiDevolvido: 0
  }
};

const stateCached = JSON.parse(sessionStorage.getItem('reduxStore'));

const initialState =  stateCached?.pdv ? stateCached?.pdv : INITIAL_STATE;
// const initialState = defineState(INITIAL_STATE)('reduxStore');

// const data = JSON.parse(sessionStorage.getItem('SIAFPLUS_USER'));
// const parametroPF054 = data?.parametros?.find(param => param.parametro === 'PF054');

const pdvReducer = (state = initialState, action) => {
  switch (action.type) {
    case INICIAR_VENDA_SUCCESS: {
      return {
        ...state,
        ...action.data
      };
    }

    case SET_SHOWMODAL_ATALHOS_PDV: {
      return {
        ...state,
        showModalAtalhosPdv: action.payload
      };
    }

    case SET_FOCUS_CONSULTA_PRODUTO: {
      return {
        ...state,
        setFocusInputPesquisaProduto: action.focus
      };
    }

    case SET_FOCUS_QUANTIDADE: {
      return {
        ...state,
        setFocusInputQuantidade: action.focus
      };
    }

    case ALTERAR_TIPO_DOCUMENTO_SUCCESS: {
      return {
        ...state,
        tipoDocumento: action.tipoDocumento
      };
    }

    case SET_STATUS: {
      return {
        ...state,
        status: action.status
      };
    }

    case SET_LOADING: {
      return {
        ...state,
        loading: action.loading
      };
    }

    case SET_LOADING_PRODUTO: {
      return {
        ...state,
        loadingProduto: action.loading
      };
    }

    case SET_LOADING_PAGAMENTO: {
      return {
        ...state,
        loadingPagamento: action.loading
      };
    }

    case SET_LOADING_SALVAMENTO: {
      return {
        ...state,
        loadingSalvamento: action.loading
      };
    }

    case SET_LOADING_IMPRESSAO: {
      return {
        ...state,
        loadingImpressao: action.loading
      };
    }

    case SET_LOADING_TRANSMISSAO: {
      return {
        ...state,
        loadingTransmissao: action.loading
      };
    }

    case SET_SHOWMODAL_CONSULTA_PRODUTO: {
      return {
        ...state,
        showModalConsultaProduto: action.payload
      };
    }

    case SET_SHOWMODAL_CONSULTA_PRODUTO_REGRA: {
      return {
        ...state,
        showModalConsultaProdutoRegra: action.payload
      }
    }

    case SET_SHOWMODAL_IMPORTAR_DOCUMENTO: {
      return {
        ...state,
        showModalImportarDocumento: action.payload
      };
    }

    case SET_SHOWMODAL_CONSULTA_CLIENTE: {
      return {
        ...state,
        showModalConsultaCliente: action.payload
      };
    }

    case SET_SHOWMODAL_CONSULTA_VENDEDOR: {
      return {
        ...state,
        showModalConsultaVendedor: action.payload
      };
    }

    case SET_SHOWMODAL_CONSULTA_PROFISSIONAL: {
      return {
        ...state,
        showModalConsultaProfissional: action.payload
      };
    }

    case SET_SHOWMODAL_CONSULTA_OPERACAO: {
      return {
        ...state,
        showModalConsultaOperacao: action.payload
      };
    }

    case SET_SHOWMODAL_CONSULTA_PAGAMENTO: {
      return {
        ...state,
        showModalConsultaPagamento: action.payload
      };
    }

    case SET_SHOWMODAL_CONFIRMA_PAGAMENTO: {
      return {
        ...state,
        showModalConfirmaPagamento: action.payload
      };
    }

    case SET_SHOWMODAL_IDENTIFICAR_CLIENTE: {
      return {
        ...state,
        showModalIdentificarCliente: action.payload
      };
    }

    case SET_SHOWMODAL_ENDERECO_CLIENTE: {
      return {
        ...state,
        showModalEnderecoCliente: action.payload
      };
    }

    case SET_SHOWMODAL_CONDICAO_PAGAMENTO_PRODUTO: {
      return {
        ...state,
        showModalCondicaoPagamentoProduto: action.payload
      };
    }

    case SET_SHOWMODAL_SALDO_ESTOQUE_PRODUTO: {
      return {
        ...state,
        showModalSaldoEstoqueProduto: action.payload
      };
    }

    case SET_SHOWMODAL_EXPEDICAO_MERCADORIA: {
      return {
        ...state,
        showModalExpedicaoMercadoria: action.payload
      };
    }

    case SET_SHOWMODAL_INFORMACAO_COMPLEMENTAR: {
      return {
        ...state,
        showModalInformacaoComplementar: action.payload
      };
    }

    case SET_SHOWMODAL_LOADING_MENSAGEM: {
      return {
        ...state,
        showModalLoadingMensagem: action.payload
      };
    }
    
    case SET_SHOWMODAL_LOADING_SALVAMENTO: {
      return {
        ...state,
        showModalLoadingSalvamento: action.payload
      };
    }

    case SET_SHOWMODAL_LIBERACAO_PEDIDO: {
      return {
        ...state,
        showModalLiberacaoPedido: { ...action.payload, callback: action.callback }
      };
    }

    case SET_SHOWMODAL_PAGAMENTO_POR_SALDO: {
      return {
        ...state,
        showModalMultiploPagamento: action.payload
      };
    }

    case ATUALIZAR_PARCELAS: {
      const { parcelas } = action;
      return {
        ...state,
        venda: {
          ...state.venda,
          parcelas
        }
      };
    }

    case ADICIONAR_PARCELAS_SUCCESS: {
      const { itemPagamento } = action;
      return {
        ...state,
        venda: {
          ...state.venda,
          parcelas:
            itemPagamento?.parcelas?.length > 0
              ? [
                  ...state?.venda?.parcelas,
                  ...itemPagamento?.parcelas?.map(parcela => ({
                    ...parcela,
                    uuid: parcela?.uuid ?? uuid(),
                    parcelaValorTotal: parcela?.parcelaValor,
                    fatCartaobandeira: itemPagamento?.fatCartaobandeira,
                    fatCartaobandeiraId: itemPagamento?.fatCartaobandeira?.id ?? null,
                    pagamentoEfetuado: false,
                    cartaoAutorizacao: itemPagamento?.documentoNumero || null,
                    documentoNumero: null  // preenchido na API
                  }))
                ]
              : [...state?.venda?.parcelas],
          fatCondicaopagamento: null,
          finMeiopagamento: itemPagamento?.meioPagamento ?? null
        }
      };
    }

    case REMOVER_PARCELA: {
      const { pagamentoId } = action;
      return {
        ...state,
        venda: {
          ...state.venda,
          parcelas: state?.venda?.parcelas?.filter(parcela => parcela.finMeiopagamentoId !== pagamentoId),
          fatCondicaopagamento: null,
          finMeiopagamento: null
        }
    }
    }

    case UPDATE_LOADING_MENSAGEM: {
      return {
        ...state,
        mensagemLoading: action.mensagem
      };
    }

    // case SELECIONAR_VENDEDOR: {
    //   return {
    //     ...state,
    //     venda: { ...state.venda, fatVendedor: action.vendedor }
    //   };
    // }

    case IDENTIFICAR_CLIENTE_SUCCESS: {
      return {
        ...state,
        clienteIdentificado: true,
        venda: { ...state.venda, ...action.cliente }
      };
    }

    case SELECIONAR_MEIO_PAGAMENTO_SUCCESS: {
      return {
        ...state,
        venda: { ...state.venda, finMeiopagamento: action.meioPagamento }
      };
    }

    case SELECIONAR_MEIO_PAGAMENTO_ENTRADA: {
      return {
        ...state,
        itemPagamento: {
          ...state.itemPagamento,
          meioPagamentoEntrada: action.meioPagamentoEntrada,
          valorEntrada: 0,
          parcelas: []
        }
      };
    }

    case ATUALIZAR_ITEM_PAGAMENTO: {
      return {
        ...state,
        itemPagamento: { ...action.itemPagamento }
      };
    }

    case ADICIONAR_PAGAMENTO_SUCCESS: {
      const { itemPagamento } = action;
      return {
        ...state,
        venda: {
          ...state.venda,
          parcelas: itemPagamento?.parcelas?.length > 0 ? [...itemPagamento?.parcelas] : [],
          fatCondicaopagamento: itemPagamento?.condicaoPagamento ?? null,
          finMeiopagamento: itemPagamento?.meioPagamento ?? null
        },
        pagamentoConfirmado: true
      };
    }

    case LIMPAR_PAGAMENTOS: {
      return {
        ...state,
        venda: {
          ...state.venda,
          parcelas: []
        }
      };
    }

    case REMOVER_PAGAMENTOS_SUCCESS: {
      const { itemsSelecteds } = action;
      const parcelas = produce(state?.venda?.parcelas ?? [], draft => {
        for (const uuid of itemsSelecteds) {
          const itemIndex = draft.findIndex(p => p.uuid === uuid);
          if (itemIndex >= 0) {
            draft.splice(itemIndex, 1);
          }
        }
      });

      return {
        ...state,
        venda: {
          ...state.venda,
          parcelas
        }
      };
    }

    case SELECIONAR_OPERACAO_SUCCESS: {
      return {
        ...state,
        venda: { ...state.venda, fisOperacao: action.operacao }
      };
    }

    case SELECIONAR_CONDICAO_PAGAMENTO_SUCCESS: {
      return {
        ...state,
        venda: {
          ...state.venda,
          fatCondicaopagamento: action.condicaoPagamento
        }
      };
    }

    case SELECIONAR_PRODUTO_SUCCESS: {
      return {
        ...state,
        itemVenda: { ...action.itemVenda }
      };
    }

    case ALTERAR_UNIDADE_COMERCIAL: {
      return {
        ...state,
        itemVenda: {
          ...state.itemVenda,
          produtoUnidadeComercial: action.unidade
        }
      };
    }

    case ALTERAR_ARMAZEM: {
      return {
        ...state,
        itemVenda: {
          ...state.itemVenda,
          produtoEstArmazem: action.armazem,
          produtoEstArmazemId: action.armazem.id
        }
      };
    }

    case ALTERAR_EXPEDICAO_MERCADORIAS: {
      return {
        ...state,
        venda: {
          ...state.venda,
          itens: (state?.venda?.itens ?? []).map((item, index) => {
            if (item?.itemCancelado) {
              return {
                ...item,
                omsModalidadeExpedicao: null,
                omsModalidadeExpedicaoId: null
              };
            }
            const omsModalidadeExpedicao =
              action.payload?.itens[index].omsModalidadeExpedicao ??
              action.payload?.omsModalidadeExpedicao;

            return {
              ...item,
              omsModalidadeExpedicao,
              omsModalidadeExpedicaoId: omsModalidadeExpedicao?.id
            };
          })
        }
      };
    }

    case EDITAR_ITEM_VENDA: {
      return {
        ...state,
        status: STATUS_PDV.VENDA,
        itemVenda: { ...action.itemVenda }
      };
    }

    case SELECIONAR_CLIENTE_SUCCESS: {
      return {
        ...state,
        venda: {
          ...state.venda,
          intCliente: action.cliente
        }
      };
    }

    case SELECIONAR_ENDERECO_CLIENTE: {
      return {
        ...state,
        venda: {
          ...state.venda,
          intClienteEnderecoId: action?.endereco?.id,
          enderecoLogradouro: action?.endereco?.logradouro,
          enderecoLogradouroNumero: action?.endereco?.logradouroNumero,
          enderecoBairro: action?.endereco?.bairro,
          enderecoCep: action?.endereco?.cep,
          enderecoIntCidade: action?.endereco?.intCidade,
          enderecoUf: action?.endereco?.uf
        }
      };
    }

    case SELECIONAR_ENDERECO_ENTREGA: {
      return {
        ...state,
        venda: {
          ...state.venda,
          entregaIntClienteEnderecoId: action?.endereco?.id,
          entregaLogradouro: action?.endereco?.logradouro,
          entregaLogradouroNumero: action?.endereco?.logradouroNumero,
          entregaBairro: action?.endereco?.bairro,
          entregaCep: action?.endereco?.cep,
          entregaIntCidade: action?.endereco?.intCidade,
          entregaUf: action?.endereco?.uf
        }
      };
    }

    case SET_TRANSFERENCIA_ITEM: {
      return {
        ...state,
        itemVenda: { ...state.itemVenda, ...action.payload }
      };
    }

    case ATUALIZAR_QUANTIDADE_SUCCESS: {
      return {
        ...state,
        itemVenda: { ...action.itemVenda }
      };
    }

    case ATUALIZAR_DESCONTO_ITEM: {
      const { itemVenda, desconto } = action;
      const percentual = Number(
        currency((desconto / (itemVenda?.itemValorUnitario * itemVenda?.itemQuantidade)) * 100, {
          precision: 2
        })
      );

      return {
        ...state,
        itemVenda: {
          ...itemVenda,
          itemValorDesconto: desconto,
          itemPercentualDesconto: percentual,
          itemValorTotal: calculaValorTotalItem({
            ...itemVenda,
            itemValorDesconto: desconto
          })
        }
      };
    }

    case ATUALIZAR_DESCONTO_ITEM_PERCENTUAL: {
      const { itemVenda, percentual } = action;
      const valorDesconto = Number(
        currency(itemVenda?.itemValorUnitario * itemVenda?.itemQuantidade * (percentual / 100))
      );

      const percentualRevisado = Number(
        currency(
          (valorDesconto / (itemVenda?.itemValorUnitario * itemVenda?.itemQuantidade)) * 100,
          {
            precision: 2
          }
        )
      );

      return {
        ...state,
        itemVenda: {
          ...itemVenda,
          itemPercentualDesconto: percentual,
          itemValorDesconto: valorDesconto,
          itemValorTotal: calculaValorTotalItem({
            ...itemVenda,
            itemValorDesconto: valorDesconto
          })
        }
      };
    }

    case ATUALIZAR_ACRESCIMO_ITEM: {
      const { itemVenda, acrescimo } = action;
      return {
        ...state,
        itemVenda: {
          ...itemVenda,
          itemValorAcrescimo: acrescimo,
          itemValorTotal: calculaValorTotalItem({
            ...itemVenda,
            itemValorAcrescimo: acrescimo
          })
        }
      };
    }

    case REMOVE_CLIENTE: {
      return {
        ...state,
        venda: {
          ...state.venda,
          intCliente: null,
          clienteCpfCnpj: null,
          clienteNome: null,
          clienteTelefone: null,
          clienteCelular: null,
          creditoCliente: null,
          enderecoBairro: null,
          enderecoCep: null,
          enderecoComplemento: null,
          enderecoIntCidade: null,
          enderecoLogradouro: null,
          enderecoLogradouroNumero: null,
          enderecoUf: null,
          intCliente: null,
          fatTabelavenda: null,
          entregaBairro: null,
          entregaCep: null,
          entregaIntCidade: null,
          entregaIntClienteEnderecoId: null,
          entregaLogradouro: null,
          entregaLogradouroNumero: null,
          entregaUf: null,
          clienteId: null
        }
      };
    }

    case REMOVER_PROFISSIONAL: {
      return {
        ...state,
        venda: {
          ...state.venda,
          fatProfissional: null,
        }
      };
    }

    case ATUALIZAR_ACRESCIMO_SUCCESS: {
      const acrescimo = action.acrescimo;
      const percentual = Number(
        currency((acrescimo / state?.venda?.valorMercadoria) * 100, {
          precision: 2
        })
      );

      const itens = atualizaValoresItens({
        ...state.venda,
        valorAcrescimo: acrescimo,
        percentualAcrescimo: percentual
      });

      const totalizadores = atualizaTotais(itens, 'valorAcrescimo');

      // verifica diferença no acrescimo
      const totalAcresicmoItems = Math.round((Number(itens.reduce((total, item) => total + item?.itemValorAcrescimo, 0)) + Number.EPSILON) * 100) / 100;

      if(totalAcresicmoItems !== totalizadores.valorAcrescimo) {
        let diferenca = 0;
        if(totalizadores.valorAcrescimo > totalAcresicmoItems) diferenca = totalizadores.valorAcrescimo - totalAcresicmoItems;
        else if(totalizadores.valorAcrescimo < totalAcresicmoItems) diferenca = totalAcresicmoItems - totalizadores.valorAcrescimo;
        const valor = itens[0].itemValorAcrescimo;
        itens[0].itemValorAcrescimo = valor + diferenca;
      }

      return {
        ...state,
        venda: {
          ...state.venda,
          itens,
          ...totalizadores,
          valorTotal: totalizadores?.valorTotal,
          valorAcrescimo: acrescimo,
          percentualAcrescimo: percentual
        },
        itemPagamento: {
          ...state?.itemPagamento,
          valorRecebido: totalizadores?.valorTotal,
          valorTroco: 0
        }
      };
    }

    case ATUALIZAR_FRETE_SUCCESS: {
      
      const frete = action.frete;
      const indiceItemFrete = Math.floor(Math.random() * state.venda.itens.length);
      const percentual = Number(
        currency((frete / state?.venda?.valorMercadoria) * 100, {
          precision: 2
        })
      );
      const itens = atualizaValoresItens({
        ...state.venda
      })
      itens[indiceItemFrete].valorFrete = frete;
      const totalizadores = atualizaTotais(itens,'valorFrete');
      return {
        ...state,
        venda: {
          ...state.venda,
          itens,
          ...totalizadores,
          valorTotal: totalizadores?.valorTotal,
          valorFrete: frete,
          percentualFrete: percentual
        },
        itemPagamento: {
          ...state?.itemPagamento,
          valorRecebido: totalizadores?.valorTotal,
          valorTroco: 0
        },
        valorFrete: frete,
        percentualFrete: percentual
      };
    }

    case ATUALIZAR_ACRESCIMO_PERCENTUAL_SUCCESS: {
      const percentual = action.percentual;
      const acrescimo =
        percentual > 0
          ? Number(
              currency(state?.venda?.valorMercadoria * (percentual / 100), {
                precision: 2
              })
            )
          : 0;

      const itens = atualizaValoresItens({
        ...state.venda,
        valorAcrescimo: acrescimo
      });

      const totalizadores = atualizaTotais(itens, 'valorAcrescimo');

      return {
        ...state,
        venda: {
          ...state.venda,
          itens,
          ...totalizadores,
          valorAcrescimo: acrescimo,
          percentualAcrescimo: percentual
        },
        itemPagamento: {
          ...state?.itemPagamento,
          valorRecebido: totalizadores?.valorTotal,
          valorTroco: 0
        }
      };
    }

    case ATUALIZAR_DESCONTO_SUCCESS: {
      const { desconto, itensParaDesconto } = action;

      const totalValorItensPermitidos = itensParaDesconto.reduce((total, item) =>
        total += (item.itemValorUnitario * item.itemQuantidade - (item.edicaoManual ? 0 : item.itemValorDesconto)), 0
      );

      const itensDescontoNaoPermitido = state.venda.itens
        .filter(item => item.fatRegradescontoProdutoId !== null && item.itemCancelado === false)
        .reduce((total, item) => total += Math.round10((item.itemValorDesconto), -2), 0
      );

      const totalValorItensNaoPermitidos = state.venda.itens
        .filter(item => item.itemCancelado === false)
        .reduce((total, item) => total += (item.itemValorUnitario * item.itemQuantidade), 0
      );

      let descontoAjustado = desconto
      const descontoNovo = Math.round10((descontoAjustado - itensDescontoNaoPermitido), -2)
      
      if (descontoNovo >= totalValorItensPermitidos || descontoAjustado < itensDescontoNaoPermitido) {
        toast.warning(
          descontoNovo >= totalValorItensPermitidos
            ? 'Desconto não permitido, valor de desconto maior que o valor dos itens'
            : 'Desconto inferior ao desconto total dos itens não permitidos para desconto'
        );

        const descontoSomenteItensNaoPermitidos = itensDescontoNaoPermitido;

        const percentualDesconto = (descontoSomenteItensNaoPermitidos / totalValorItensNaoPermitidos) * 100;
        
        const itensAtualizados = state.venda.itens.map(item => {
          if (itensParaDesconto.includes(item)) {
            return {
              ...item,
              itemValorDesconto: 0,
              itemPercentualDesconto: 0,
              itemValorTotal: Math.round10((item.itemValorUnitario * item.itemQuantidade), -2)
            };
          }
          return item;
        });

        const totalizadores = atualizaTotais(itensAtualizados, 'valorDesconto');
        
        return {
          ...state,
          venda: {
            ...state.venda,
            itens: itensAtualizados,
            ...totalizadores,
            valorDesconto: descontoSomenteItensNaoPermitidos,
            percentualDesconto
          },
          itemPagamento: {
            ...state?.itemPagamento,
            valorRecebido: totalizadores?.valorTotal,
            valorTotal: totalizadores?.valorTotal,
            valorTroco: 0
          }
        };
      }

      let valorMercadoriaItensSemRegra = itensParaDesconto.reduce((total, item) =>
        (total += (item?.itemValorUnitario * item?.itemQuantidade)), 0
      );

      valorMercadoriaItensSemRegra = Math.round10(valorMercadoriaItensSemRegra * 100) / 100

      const percentualDescontoTotal = Math.round10(((desconto / totalValorItensNaoPermitidos) * 100), -2)

      state.venda.percentualDesconto = percentualDescontoTotal;
  
      const itens = state.venda.itens.map(item => {
        if (itensParaDesconto.includes(item)) {
          let itemValorBruto = item.itemValorUnitario * item.itemQuantidade;
          
          let descontoNovoProporcional = Math.round10(((itemValorBruto / valorMercadoriaItensSemRegra) * descontoNovo), -2);

          const percentualDescontoItens = Math.round10(((descontoNovoProporcional / itemValorBruto) * 100), -2)
          
          const itemValorDescontoFinal = descontoNovoProporcional
          // const itemValorDescontoFinal = Math.min((desconto > 0 ? item.itemValorDesconto : 0) + descontoNovoProporcional, itemValorBruto);
          let itemValorDesconto = item.edicaoManual ? descontoNovoProporcional : itemValorDescontoFinal

          return {
            ...item,
            itemPercentualDesconto: percentualDescontoItens,
            itemValorDesconto:  itemValorDesconto,
            itemValorTotal: Math.round10((itemValorBruto) - (itemValorDesconto), -2)
          };
        }
        return item;
      });
  
      const itensNaoCancelados = itens.filter(item => !item.itemCancelado && !(item.edicaoManual && item.itemValorUnitario < item.produtoPrecoVenda) && !item.fatRegradescontoProdutoId);
      const totalDescontoItems = itensNaoCancelados.reduce((total, item) => (total += item?.itemValorTotal), 0);
      const totalComDesconto = Math.round10((totalDescontoItems + descontoNovo), -2);

      if (totalComDesconto !== valorMercadoriaItensSemRegra) {
        const diferenca = Math.round10((valorMercadoriaItensSemRegra - totalComDesconto), -2);
        const maxValue = Math.max(...itensNaoCancelados.map((item) => item.itemValorTotal), 0);
        const index = itens.findIndex(item => item.itemValorTotal === maxValue && !(item.edicaoManual && item.itemValorUnitario < item.produtoPrecoVenda) && !item.fatRegradescontoProdutoId);
        
        if (index !== -1) {
          const result = itens[index].itemValorTotal + parseFloat(diferenca.toFixed(2));
          itens[index].itemValorTotal = parseFloat(result.toFixed(2));
          itens[index].itemValorDesconto += diferenca > 0 ? (parseFloat(diferenca.toFixed(2)) * (-1)) : parseFloat(diferenca.toFixed(2));
        }
      }

      const totalizadores = atualizaTotais(itens, 'valorDesconto');

      return {
        ...state,
        venda: {
          ...state.venda,
          itens,
          ...totalizadores,
          valorDesconto: desconto,
          percentualDesconto: percentualDescontoTotal
        },
        itemPagamento: {
          ...state?.itemPagamento,
          valorRecebido: totalizadores?.valorTotal,
          valorTotal: totalizadores?.valorTotal,
          valorTroco: 0
        }
      };
    }

    case ATUALIZAR_DESCONTO_PERCENTUAL_SUCCESS: {
      const { percentual, itensParaDesconto } = action;

      const totalValorItensNaoPermitidos = state.venda.itens
        .filter(item => item.itemCancelado === false)
        .reduce((total, item) => total += (item.itemValorUnitario * item.itemQuantidade), 0
      );

      let valorMercadoriaItensSemRegra = state.venda.itens
        .filter(item => item.itemCancelado === false)
        .reduce((total, item) => (total += (item?.itemValorUnitario * item?.itemQuantidade)), 0
      );

      valorMercadoriaItensSemRegra = Math.round10(valorMercadoriaItensSemRegra * 100) / 100

      const totalValorItensParaDesconto = itensParaDesconto.reduce((total, item) =>
        (total += (item?.itemValorUnitario * item?.itemQuantidade)), 0
      );
      
      const totalValorItensPermitidos = itensParaDesconto.reduce((total, item) =>
        total += (item.itemValorUnitario * item.itemQuantidade - (item.edicaoManual ? 0 : item.itemValorDesconto)), 0
      );
      
      const itensDescontoNaoPermitido = state.venda.itens 
        .filter(item => item.fatRegradescontoProdutoId !== null && item.itemCancelado === false)
        .reduce((total, item) => total += Math.round10((item.itemValorDesconto), -2), 0
      );

      let desconto = Math.round10((valorMercadoriaItensSemRegra * (percentual / 100)), -2);
    
      let descontoAjustado = desconto
      const descontoNovo = Math.round10((descontoAjustado - itensDescontoNaoPermitido), -2)

      if (descontoNovo >= totalValorItensPermitidos || descontoAjustado < itensDescontoNaoPermitido) {
        toast.warning(
          descontoNovo >= totalValorItensPermitidos
            ? 'Desconto não permitido, valor de desconto maior que o valor dos itens'
            : 'Desconto inferior ao desconto total dos itens não permitidos para desconto'
        );

        const descontoSomenteItensNaoPermitidos = itensDescontoNaoPermitido;

        const percentualDesconto = (descontoSomenteItensNaoPermitidos / totalValorItensNaoPermitidos) * 100;
        
        const itensAtualizados = state.venda.itens.map(item => {
          if (itensParaDesconto.includes(item)) {
            return {
              ...item,
              itemValorDesconto: 0,
              itemPercentualDesconto: 0,
              itemValorTotal: Math.round10((item.itemValorUnitario * item.itemQuantidade), -2)
            };
          }
          return item;
        });
        
        const totalizadores = atualizaTotais(itensAtualizados, 'valorDesconto');
        
        return {
          ...state,
          venda: {
            ...state.venda,
            itens: itensAtualizados,
            ...totalizadores,
            valorDesconto: descontoSomenteItensNaoPermitidos,
            percentualDesconto
          },
          itemPagamento: {
            ...state?.itemPagamento,
            valorRecebido: totalizadores?.valorTotal,
            valorTotal: totalizadores?.valorTotal,
            valorTroco: 0
          }
        };
      }

      state.venda.percentualDesconto = percentual;
      const itensSemModificacao = state.venda.itens.filter(item => !item.itemCancelado && ((item.edicaoManual && item.itemValorUnitario < item.produtoPrecoVenda) || item.fatRegradescontoProdutoId))

      const itens = state.venda.itens.map(item => {
        if (itensParaDesconto.includes(item)) {
          let itemValorBruto = item.itemValorUnitario * item.itemQuantidade;
          
          let descontoNovoProporcional = Math.round10(((itemValorBruto / totalValorItensParaDesconto) * descontoNovo), -2);
          
          const itemValorDescontoFinal = descontoNovoProporcional
          // const itemValorDescontoFinal = Math.min((desconto > 0 ? item.itemValorDesconto : 0) + descontoNovoProporcional, itemValorBruto);
          let itemValorDesconto = item.edicaoManual ? descontoNovoProporcional : itemValorDescontoFinal;
          let novoPercentualItem = Math.round10((itemValorDesconto / itemValorBruto) * 100, -2);

          return {
            ...item,
            itemPercentualDesconto: itensSemModificacao.length ? novoPercentualItem : percentual,
            itemValorDesconto:  itemValorDesconto,
            itemValorTotal: Math.round10((itemValorBruto) - (itemValorDesconto), -2)
          };
        }
        return item;
      });

      const itensNaoCancelados = itens.filter(item => !item.itemCancelado && !(item.edicaoManual && item.itemValorUnitario < item.produtoPrecoVenda) && !item.fatRegradescontoProdutoId);
      const totalDescontoItems = itensNaoCancelados.reduce((total, item) => (total += item?.itemValorTotal), 0);
      const totalComDesconto = Math.round10((totalDescontoItems + descontoNovo), -2);

      if (totalComDesconto !== totalValorItensParaDesconto) {
        const diferenca = Math.round10((totalValorItensParaDesconto - totalComDesconto), -2);
        const maxValue = Math.max(...itensNaoCancelados.map((item) => item.itemValorTotal), 0);
        const index = itens.findIndex(item => item.itemValorTotal === maxValue && !(item.edicaoManual && item.itemValorUnitario < item.produtoPrecoVenda) && !item.fatRegradescontoProdutoId);
        
        if (index !== -1) {
          const result = itens[index].itemValorTotal + parseFloat(diferenca.toFixed(2));
          itens[index].itemValorTotal = parseFloat(result.toFixed(2));
          itens[index].itemValorDesconto += diferenca > 0 ? (parseFloat(diferenca.toFixed(2)) * (-1)) : parseFloat(diferenca.toFixed(2));
        }
      }

      const totalizadores = atualizaTotais(itens, 'valorDesconto');

      return {
        ...state,
        venda: {
          ...state.venda,
          itens,
          ...totalizadores,
          valorDesconto: desconto,
          percentualDesconto: percentual
        },
        itemPagamento: {
          ...state?.itemPagamento,
          valorRecebido: totalizadores?.valorTotal,
          valorTroco: 0
        }
      };
    }

    case ATUALIZAR_DESCONTO_SUCCESS_SEM_PARAMS: {
      const percentual = Number((action.desconto / state?.venda?.valorMercadoria) * 100);
      const desconto = Math.round((Number(state?.venda?.valorMercadoria * (percentual / 100)) + Number.EPSILON) * 100) / 100;
      state.venda.percentualDesconto = percentual;
      const itens = atualizaValoresItens({
        ...state.venda,
        valorDesconto: desconto,
        itens: state?.venda?.itens?.map(item => ({ 
          ...item, 
          itemPercentualDesconto: 0, 
          itemValorDesconto: 0 
        }))
      });

      const itensNaoCancelados = itens.filter(item => !item.itemCancelado);
      const totalDescontoItems = itensNaoCancelados.reduce((total, item) => (total += item?.itemValorTotal), 0);
      const totalComDesconto = parseFloat((totalDescontoItems + desconto).toFixed(2)) ;
      if(totalComDesconto !== state.venda.valorMercadoria) {
        const diferenca = state.venda.valorMercadoria - totalComDesconto;

        const maxValue = Math.max(...itens.map((item) => item.itemValorTotal), 0);

        const index = itens.map(e => e.itemValorTotal).indexOf(maxValue);

        const result = itens[index].itemValorTotal + parseFloat(diferenca.toFixed(2));

        itens[index].itemValorTotal = parseFloat(result.toFixed(2));
      }
      
      const totalizadores = atualizaTotais(itens, 'valorDesconto');

      return {
        ...state,
        venda: {
          ...state.venda,
          itens,
          ...totalizadores,
          valorDesconto: desconto,
          percentualDesconto: percentual
        },
        itemPagamento: {
          ...state?.itemPagamento,
          valorRecebido: totalizadores?.valorTotal,
          valorTotal: totalizadores?.valorTotal,
          valorTroco: 0
        }
      };
    }

    case ATUALIZAR_DESCONTO_PERCENTUAL_SUCCESS_SEM_PARAMS: {
      const percentual = action.percentual;
      let desconto =
        percentual > 0 ? Number(state?.venda?.valorMercadoria * (percentual / 100)) : 0;
        state.venda.percentualDesconto = percentual;
        desconto = parseFloat(desconto.toFixed(2));
      let itens = atualizaValoresItens({
        ...state.venda,
        valorDesconto: desconto,
        itens: state.venda?.itens?.map(item => ({ 
          ...item, 
          itemPercentualDesconto: 0, 
          itemValorDesconto: 0 
        }))
      });

      const itensNaoCancelados = itens.filter(item => !item.itemCancelado);
      const totalDescontoItems = itensNaoCancelados.reduce((total, item) => (total += item?.itemValorTotal), 0);
      const totalComDesconto = parseFloat((totalDescontoItems + desconto).toFixed(2)) ;
      if(totalComDesconto !== state.venda.valorMercadoria) {
        const diferenca = state.venda.valorMercadoria - totalComDesconto;

        const maxValue = Math.max(...itens.map((item) => item.itemValorTotal), 0);

        const index = itens.map(e => e.itemValorTotal).indexOf(maxValue);

        const result = itens[index].itemValorTotal + parseFloat(diferenca.toFixed(2));

        itens[index].itemValorTotal = parseFloat(result.toFixed(2));
      }

      const totalizadores = atualizaTotais(itens, 'valorDesconto');

      return {
        ...state,
        venda: {
          ...state.venda,
          itens,
          ...totalizadores,
          valorDesconto: desconto,
          percentualDesconto: percentual
        },
        itemPagamento: {
          ...state?.itemPagamento,
          valorRecebido: totalizadores?.valorTotal,
          valorTroco: 0
        }
      };
    }

    case ADICIONAR_PRODUTO_SUCCESS: {
      const { itemVenda } = action;

      let itens = [
        ...(state?.venda?.itens ?? []),
        {
          ...itemVenda,
          uuid: itemVenda?.uuid ?? uuid()
        }
      ];
      const itensAtualizados = atualizaValoresItens({
        ...state.venda,
        itens
      });

      const totalizadores = atualizaTotais(itens, state.verificaMovimentaFinanceiro)

      // verifica diferença no acrescimo
      const filterItens = itens.filter(item => !item.itemCancelado);
      const totalAcresicmoItems = Math.round((Number(filterItens.reduce((total, item) => total + item?.itemValorAcrescimo, 0)) + Number.EPSILON) * 100) / 100;

      if(totalAcresicmoItems !== totalizadores.valorAcrescimo) {
        let diferenca = 0;
        if(totalizadores.valorAcrescimo > totalAcresicmoItems) diferenca = totalizadores.valorAcrescimo - totalAcresicmoItems;
        else if(totalizadores.valorAcrescimo < totalAcresicmoItems) diferenca = totalAcresicmoItems - totalizadores.valorAcrescimo;
        const valor = itens[0].itemValorAcrescimo;
        itens[0].itemValorAcrescimo = valor + diferenca;
      }

      return {
        ...state,
        venda: {
          ...state.venda,
          itens,
          ...totalizadores
        }
      };
    }

    case ATUALIZAR_PRODUTO: {
      const { itemVenda } = action;

      const itens = produce(state?.venda?.itens ?? [], draft => {
        const itemIndex = draft.findIndex(i => i.uuid === itemVenda.uuid);
        if (itemIndex >= 0) {
          draft[itemIndex] = itemVenda;
        }
      });

      const itensAtualizados = atualizaValoresItens({
        ...state.venda,
        itens
      });

      return {
        ...state,
        venda: {
          ...state.venda,
          itens,
          ...atualizaTotais(itens, state.verificaMovimentaFinanceiro)
        }
      };
    }

    case REMOVER_PRODUTO_SUCCESS: {
      const { uuid } = action;
      const itens = produce(state?.venda?.itens ?? [], draft => {
        const itemIndex = draft.findIndex(p => p.uuid === uuid);
        if (itemIndex >= 0) {
          draft.splice(itemIndex, 1);
        }
      });

      const newItens = verificaItemEstoque(itens, state?.venda?.intEmpresaId || state.venda.fisOperacao.intEmpresaId);

      return {
        ...state,
        venda: {
          ...state.venda,
          itens: newItens,
          ...atualizaTotais(itens, state.verificaMovimentaFinanceiro)
        }
      };
    }

    case ATUALIZAR_ITENS: {
      const itens = [...action.itens].map((itemAtualizacao, index) => {
        const itemVendido = state?.venda?.itens[index] || {};

        const itemMergeado = {
          ...itemVendido,
          ...itemAtualizacao,
          index,
          uuid: itemVendido?.uuid ?? uuid(),
          itemValorTotal: calculaValorTotalItem(itemAtualizacao),
          itemValorDesconto: itemAtualizacao?.itemValorDesconto,
          fatRegradescontoProdutoId: itemAtualizacao?.fatRegradescontoProdutoId
        }

        return itemMergeado

        // return {
        //   ..._.merge(itemVendido, itemAtualizacao),
        //   index,
        //   uuid: itemVendido?.uuid ?? uuid(),
        //   itemValorTotal: calculaValorTotalItem(itemAtualizacao),
        //   itemValorDesconto: itemAtualizacao?.itemValorDesconto,
        //   fatRegradescontoProdutoId: itemAtualizacao?.fatRegradescontoProdutoId
        // };
      });

      return {
        ...state,
        venda: {
          ...state.venda,
          itens,
          ...atualizaTotais(itens, state.verificaMovimentaFinanceiro, null, state.venda)
        }
      };
    }

    case CANCELAR_PRODUTO_SUCCESS: {
      const { id } = action;
      const itens = produce(state?.venda?.itens ?? [], draft => {
        const itemIndex = draft.findIndex(p => p.id === id);
        if (itemIndex >= 0) {
          draft[itemIndex].itemCancelado = true;
        }
      });

      return {
        ...state,
        venda: {
          ...state.venda,
          itens,
          ...atualizaTotais(itens, state.verificaMovimentaFinanceiro)
        }
      };
    }

    case SET_INFORMACAO_COMPLEMENTAR: {
      return {
        ...state,
        venda: {
          ...state.venda,
          informacaoComplementar: action.informacaoComplementar.informacaoComplementar,
          xPed: action.informacaoComplementar.xPed,
          xCont: action.informacaoComplementar.xCont
        }
      };
    }

    case ATUALIZAR_NUMERO_DOCUMENTO_CARTAO: {
      return {
        ...state,
        itemPagamento: {
          ...state.itemPagamento,
          [action?.entrada ? 'meioPagamentoEntrada' : 'meioPagamento']: {
            ...state.itemPagamento[action?.entrada ? 'meioPagamentoEntrada' : 'meioPagamento'],
            cartaoAutorizacao: action?.documento
          }
        }
      };
    }

    case ATUALIZAR_BANDEIRA_CARTAO: {
      return {
        ...state,
        itemPagamento: {
          ...state.itemPagamento,
          [action?.entrada ? 'meioPagamentoEntrada' : 'meioPagamento']: {
            ...state.itemPagamento[action?.entrada ? 'meioPagamentoEntrada' : 'meioPagamento'],
            fatCartaobandeira: action?.bandeira,
            fatCartaobandeiraId: action?.bandeira.id
          }
        }
      };
    }

    case SET_MULTIPLOS_PAGAMENTOS: {
      return {
        ...state,
        multiplosPagamentos: action?.multiplosPagamentos
      };
    }

    case SET_SHOWMODAL_CADASTRO_CHEQUE: {
      return {
        ...state,
        showModalCadastroCheque: action?.payload
      };
    }

    case ADICIONAR_CHEQUE_PARCELA: {
      const { uuid, payload } = action;
      const parcelas = produce(state?.itemPagamento?.parcelas ?? [], draft => {
        const itemIndex = draft.findIndex(p => p.uuid === uuid);
        draft[itemIndex].cheque = payload;
      });

      return {
        ...state,
        itemPagamento: {
          ...state.itemPagamento,
          parcelas
        }
      };
    }

    case REMOVER_CHEQUE_PARCELA: {
      const { uuid } = action;
      const parcelas = produce(state?.itemPagamento?.parcelas ?? [], draft => {
        const itemIndex = draft.findIndex(p => p.uuid === uuid);
        draft[itemIndex].cheque = null;
      });

      return {
        ...state,
        itemPagamento: {
          ...state.itemPagamento,
          parcelas
        }
      };
    }

    case SET_SHOWMODAL_LISTA_PAGAMENTOS: {
      return {
        ...state,
        showModalListaPagamentos: action?.payload
      };
    }

    case SET_SHOWMODAL_PREVIEW_DANFE: {
      return {
        ...state,
        showModalPreviewDanfe: action?.payload
      };
    }

    case SET_SHOWMODAL_TROCA_USUARIO: {
      return {
        ...state,
        showModalTrocaUsuario: action?.payload
      };
    }

    case SET_SHOWMODAL_CONSULTA_RAPIDA_PRODUTO: {
      return {
        ...state,
        showModalConsultaRapidaProduto: action?.payload
      };
    }

    case SET_SHOWMODAL_DESCONTO: {
      return {
        ...state,
        showModalDesconto: action?.payload
      };
    }

    case SET_SHOWMODAL_ACRESCIMO: {
      return {
        ...state,
        showModalAcrescimo: action?.payload
      };
    }

    case SET_SHOWMODAL_FRETE: {
      return {
        ...state,
        showModalFrete: action?.payload
      };
    }

    case FECHAR_VENDA_SUCCESS: {
      return {
        ...state,
        documentoSalvo: action?.payload
      };
    }

    case FECHAR_VENDA_ERROR: {
      return {
        ...state,
        documentoSalvo: action?.payload
      };
    }

    case LIMPAR_VENDA: {
      return {
        ...state,
        venda: {
          ...INITIAL_STATE.venda
        }
      };
    }

    default:
      return state;
  }
};

export default pdvReducer;
